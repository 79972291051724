import './App.css';
import Navigation from './navigation/Navigation';

function App() {
  return (
    <Navigation />
  )
}

export default App;
